@use "sass:math";
//
//      Nav local
//
.o-nav-local {
    margin-bottom: ($spacer * 2);
    border-top: math.div($border-width, 2) solid $gray-500;
    border-bottom: math.div($border-width, 2) solid $gray-500;
  
    .nav-link {
      position: relative;
      padding: .875rem $nav-link-padding-x;
  
      &:focus {
        outline-offset: -#{$border-width};
      }
  
      &.active::before {
        position: absolute;
        right: $nav-link-padding-x;
        bottom: -#{math.div(map-get($spacers, 2), 10)};
        left: $nav-link-padding-x;
        height: $border-width * 2;
        content: "";
        background-color: currentColor;
      }
    }
  
    .nav > .nav-item:first-child {
      .nav-link {
        padding-left: 0;
      }
  
      .active::before {
        left: 0;
      }
    }
  
    &.navbar-dark {
      border-color: $gray-900;
    }
  }
  