// Boosted mod : custom buttons
.btn-primary,
.btn-warning {
  &.btn-inverse {
    @include button-variant($primary, $primary, $black, $white, $white, $black, $gray-700, $gray-700, $black, $gray-700, $gray-700, $black);
  }
}

.btn-outline-secondary,
.btn-light {
  &.btn-inverse {
    @include button-variant($black, $white, $white, $white, $white, $black, transparent, $gray-700, $gray-700, transparent, $gray-700, $gray-700);
  }
}

.btn-success {
  &.btn-inverse {
    @include button-variant($success, $success, $white, $white, $primary, $primary, $gray-700, $gray-700, $black, $gray-700, $gray-700, $black);
  }
}

.btn-info,
.btn-dark {
  &.btn-inverse {
    @include button-variant($white, $white, $black, $black, $white, $white, $gray-700, $gray-700, $black, $gray-700, $gray-700, $black);
  }
}

.btn-danger.btn-inverse {
  @include button-variant($danger, $danger, $white, $white, $white, $black, $gray-700, $gray-700, $black, $gray-700, $gray-700, $black);
}

.btn-link.btn-inverse {
  color: $white;
}