.bs-datepicker .bs-datepicker-container {
  padding: 0;
  border: 2px solid #000;
  .bs-datepicker-head,
  .bs-datepicker-body {
    border-radius: 0;
  }
  .bs-datepicker-head {
    background-color: #000 !important;
    bs-datepicker-navigation-view {
      display: flex;
      justify-content: space-between;
    }
    button[disabled] {
      opacity: 0;
    }
  }
  .bs-datepicker-body {
    padding: 0;
    min-height: 200px;
    border: 0;

    .days.weeks thead {
      background: #000;
    }
  }
  table {
    th {
      color: #fff;
    }
    td {
      color: #000;

      span.selected,
      &.selected span,
      span[class*='select-']:after,
      &[class*='select-'] span:after {
        background-color: #f16e00;
      }
      &.week span {
        color: #f16e00;
      }
      span {
        border-radius: 0;
      }
    }
  }
}