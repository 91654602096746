.mini {
  --font-size: 0.875rem;
  --vcrm-grey: #ddd;

  font-size: var(--font-size);

  .dropdown-menu{
    font-size: var(--font-size);
  }
  
  select, input {
   // height: 30px;
    border-width: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .form-select,.form-control{
    font-size: var(--font-size);
  }
}


