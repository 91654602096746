// Redefine modal size
$modal-sm: 300px;
$modal-md: 700px;
$modal-lg: 850px;
$modal-xl: 940px;

@import "node_modules/boosted/scss/boosted";
@import "./styles-mini";
@import "./styles/vcrm-icons.css";
@import "./styles/scss/calendar";
@import "./styles/scss/chartjs";
@import "./styles/scss/o-footer";
@import "./styles/scss/o-nav-local";
@import "./styles/scss/o-stepbar";
@import "./styles/scss/btn-inverse";

*:focus {
    outline: none !important;
}

/**  Scrollbar definition **/
*::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    padding: 2px;
    background-color: #ddd;

    /* or add it to the track */
}

*::-webkit-scrollbar-corner {
    background-color: #ddd;
}

*::-webkit-scrollbar-thumb {
    background: #000;
    border: 2px solid #ddd;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

// redefine margin of container-fluid
.container-fluid {
    max-width: 100%;
}

// Override button styles
.btn {
    &.btn-link,
    &.btn-link:hover {
        text-decoration: none;
    }

    &.btn-icon {
        i::before {
            font-size: 20px;
            position: relative;
            top: 1px;
        }

        &.btn-sm i::before {
            font-size: 16px;
        }
    }
}

// Used by ng-http-loader to display loading spinner
#spinner {
    background-color: rgb(238 238 238 / 90%) !important;
}

// Disable native style on button
button {
    background: transparent;
    padding: 0;
    border: none;
}

// Redefine z-index for all dropdown-menu directly under body element (used by typehead-container when shared a dashboard)
body > .dropdown-menu {
    z-index: 1100 !important;
}

// Définit la taille de la liste déroulante dans la popin de création d'une nouvelle comparaison de dashboard (définit ici car elle est sur le body)
.dropdown-menu.show.dashboard-list {
    width: 415px;
    max-height: 300px;
    overflow: auto;
}

// Remove underline on all links
a,
a:hover {
    text-decoration: none;
}

// Add usefull class
.cursor-pointer {
    cursor: pointer;
}

.cursor-auto {
    cursor: auto !important;
}

.fs-7 {
    font-size: 0.875rem !important;
}

.text-color-danger {
    color: #cd3c14;
}

.text-color-success {
    color: #32c832;
}

.text-color-info {
    color: #527edb;
}

.text-color-primary {
    color: $primary;
}

.text-underline {
    text-decoration: underline !important;
}

.text-first-letter-uppercase::first-letter {
    text-transform: uppercase;
}

.table > :not(:first-child) {
    border-top-width: 0;
}

.table td {
    vertical-align: middle;
}

// Disable selection on text for all accordion title
.accordion-toggle {
    user-select: none;
}

// Add margin on form and label (compatibility from boosted 4.6)
.form-group {
    margin-bottom: 20px;
}

label {
    margin-bottom: 6px;
}

// Add cursor on hover a form-label element
.form-label:hover {
    cursor: pointer;
}

// Remove outline border on radio button
.form-check .form-label::before {
    outline: 0 !important;
}

// Add position absolute on popover (used by ngx-boostrap lib)
.popover {
    position: absolute;

    .popover-arrow {
        position: absolute;
    }
}

.tooltip {
    position: absolute;

    .tooltip-arrow {
        position: absolute;
    }
}

.modal-dialog {
    max-height: calc(100% - (0.625rem * 2));
    height: calc(100% - 3.75rem);

    // Disable border on focus close button in modal header but not on hover
    .modal-header {
        justify-content: space-between;
    }

    // Always content scrollable (normaly use .modal-dialog-scrollable class)
    .modal-content {
        max-height: 100%;
        overflow: hidden;

        .modal-body {
            overflow-y: auto;
            padding: 2px 20px;
            margin: 20px 0 10px;
        }
    }

    // Used to redefine accordion style with dropdown and card in modal
    .modal-body .panel {
        .card .card-header {
            padding: 0;
            background: #ededed;
            border-color: #fff;

            .btn-link {
                display: block;
                width: 100%;
                text-align: left;
                padding: 0.5rem;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 2rem;

                &::after {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    display: inline-block;
                    content: "";
                    border: 0.4375rem solid;
                    border-right-color: transparent;
                    border-left-color: transparent;
                    border-bottom-width: 0;
                    transform: translateY(-50%);
                }
            }
        }

        &.panel-open .card .card-header .btn-link::after {
            transform: translateY(-50%) rotateZ(180deg);
        }

        .card-body {
            padding: 0;
        }
    }
}

@media (width >= 480px) {
    .modal-dialog {
        max-height: calc(100% - (1.875rem * 2));
    }
}

.thematic-modal,
.heatmap-modal,
.new-comparison-modal,
.select-dashboard-modal {
    max-width: 1300px;
    max-height: unset;
}

.new-comparison-modal .modal-content {
    padding: 0;
}

.modal-full-width {
    display: flex;
    max-width: 90%;
    height: 90%;
}

.modal-500 {
    max-width: 500px;
}

.modal-700 {
    max-width: 90%;
    .modal-body {
        height: 700px;
    }
}

.sentiment-mixed {
    color: --bs-primary;
}

.sentiment-positive {
    color: $functional-green;
}

.sentiment-negative {
    color: $functional-red;
}

.sentiment-neutral {
    color: $secondary;
}

.badge-filter-count {
    top: -4px;
    margin-left: 3px;
    font-size: 16px;
    height: 25px;
    min-width: 25px;
    line-height: 20px;
    padding: 2px;
}

.percent-color-0 {
    background-color: #cd3c14;
    color: #fff;
}

.percent-color-20 {
    background-color: #ff7900;
    color: #000;
}

.percent-color-40 {
    background-color: #fc0;
    color: #000;
}

.percent-color-80 {
    background-color: #666;
    color: #fff;
}

.percent-color-101 {
    background-color: #32c832;
    color: #000;
}

.tooltip-legend-merged {
    width: 16px !important;
    height: 16px !important;
    text-align: center;
    line-height: 16px;
    margin: 2px;
    border: 0 !important;
    font-weight: bold;
}

.alert-no-outline{
    border:0;
    padding:0;
}

svg.solaris-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}