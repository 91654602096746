// Use to customize ChartJS tooltips
#chartjs-tooltip {
    background-color: rgb(0 0 0 / 80%);
    border-radius: 6px;
    color: white;
    pointer-events: none;
    padding: 6px;
    top: 0;
    left: 0;
    max-width: 400px;
    transform: translate(-50%, 0);
    transition: opacity 0.1s ease;
    font-size: 12px;
    z-index: 1050;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -5px;
        right: calc(50% - 5px);
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: #000 transparent transparent;
    }

    &.left {
        &::after {
            left: -5px;
            right: initial;
            bottom: calc(50% - 5px);
            border-width: 5px 5px 5px 0;
            border-color: transparent #000 transparent transparent;
        }
    }

    &.right {
        &::after {
            left: initial;
            right: -5px;
            bottom: calc(50% - 5px);
            border-width: 5px 0 5px 5px;
            border-color: transparent transparent transparent #000;
        }
    }

    .tooltip-title {
        font-weight: bold;
        margin-bottom: 4px;
    }

    .tooltip-legend {
        display: inline-block;
        margin-right: 4px;
        width: 12px;
        height: 12px;
        border: 1px solid #fff;
        vertical-align: middle;
    }
}
