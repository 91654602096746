@font-face {
    font-family: vcrm-icons;
    src:
        url("fonts/vcrm-icons.ttf?if2f0g") format("truetype"),
        url("fonts/vcrm-icons.woff?if2f0g") format("woff"),
        url("fonts/vcrm-icons.woff?if2f0g") format("woff2"),
        url("fonts/vcrm-icons.svg?if2f0g#vcrm-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="vcrm-"],
[class*=" vcrm-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: vcrm-icons !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.vcrm-bookmark-fill:before {
    content: "\e92f";
}

.vcrm-message-group:before {
    content: "\e92a";
}

.vcrm-ai-generative::before {
    content: "\e92e";
}

.vcrm-accessibility-vision::before {
    content: "\e92d";
}
  
.vcrm-form-minus-default::before {
    content: "\e92b";
}

.vcrm-send::before {
    content: "\e92c";
}

.vcrm-ethics::before {
    content: "\e929";
}

.vcrm-pencil-star::before {
    content: "\e927";
}

.vcrm-undo::before {
    content: "\e928";
}

.vcrm-copy::before {
    content: "\e98d";
}

.vcrm-form-triangle-down::before {
    content: "\e91e";
}

.vcrm-form-triangle-up::before {
    content: "\e91f";
}

.vcrm-syncronise::before {
    content: "\e91b";
}

.vcrm-clock-white::before {
    content: "\e91c";
}

.vcrm-form-cross::before {
    content: "\e91d";
}

.vcrm-form-chevron-right::before {
    content: "\e920";
}

.vcrm-form-chevron-left::before {
    content: "\e921";
}

.vcrm-calendar-day::before {
    content: "\e922";
}

.vcrm-info::before {
    content: "\e923";
}

.vcrm-modifier-delete::before {
    content: "\e924";
}

.vcrm-more-add::before {
    content: "\e925";
}

.vcrm-trash::before {
    content: "\e926";
}

.vcrm-tick::before {
    content: "\e91a";
}

.vcrm-avatar::before {
    content: "\e915";
}

.vcrm-search::before {
    content: "\e919";
}

.vcrm-newspaper::before {
    content: "\e910";
}

.vcrm-email::before {
    content: "\e911";
}

.vcrm-plaza::before {
    content: "\e912";
}

.vcrm-guide-help::before {
    content: "\e913";
}

.vcrm-administrator::before {
    content: "\e914";
}

.vcrm-session-leave::before {
    content: "\e916";
}

.vcrm-assistance::before {
    content: "\e917";
}

.vcrm-notification-alert::before {
    content: "\e918";
}

.vcrm-funny::before {
    content: "\e905";
}

.vcrm-display-list::before {
    content: "\e90e";
}

.vcrm-tag-cloud::before {
    content: "\e90f";
}

.vcrm-graph-column-chart::before {
    content: "\e90b";
}

.vcrm-graph-curve-chart::before {
    content: "\e90c";
}

.vcrm-graph-combination-chart::before {
    content: "\e90d";
}

.vcrm-pencil::before {
    content: "\e908";
}

.vcrm-save::before {
    content: "\e909";
}

.vcrm-share::before {
    content: "\e90a";
}

.vcrm-star-favourite::before {
    content: "\e907";
}

.vcrm-guide-info::before {
    content: "\e906";
}

.vcrm-menu::before {
    content: "\e901";
}

.vcrm-graph-pie-chart::before {
    content: "\e902";
}

.vcrm-tag::before {
    content: "\e903";
}

.vcrm-home::before {
    content: "\e904";
}

.vcrm-hide::before {
    content: "\e900";
}

.vcrm-settings::before {
    content: "\eafd";
}
